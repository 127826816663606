<template>
  <div class="col school_students_class_page_wrapper" v-loading="loading">
    <!-- Start Info table  -->
    <!-- getLocale dir="rtl" -->
    <div>
      <Info
        v-if="groupInfo != {}"
        :items="groupInfo"
        :style="{ 'direction: rtl': getLocale == 'ar' }"
      ></Info>
    </div>
    <!-- End Info table  -->

    <!-- Start Info table  -->
    <card>
      <div class="some_info_about_teacher tech">
        <span class="d-block">
          {{ $t("admin_dashboard_type.teacher_info") }}
        </span>

        <div class="every_table_action_button">
          <button
            class="our_btn text-capitalize"
            @click="showAddTeacherDialog = true"
          >
            <el-icon class="el-icon-plus"></el-icon>

            {{ $t("admin_dashboard_type.add_teacher") }}
          </button>
        </div>
      </div>

      <div class="wrapper_teacher_info text-capitalize" v-if="teacherDetails">
        <div class="part_aa">
          <span class="d-block names">
            {{ $t("admin_dashboard_type.teacher_name") }}</span
          >
          <span class="d-block teach_in">{{ teacherDetails.name }}</span>
        </div>
        <div class="part_aa part_bb">
          <span class="d-block names">
            {{ $t("admin_dashboard_type.specialization") }}</span
          >
          <span class="d-block teach_in">
            {{ whichSpecialization(teacherDetails.specialization) }}
          </span>
        </div>
      </div>
    </card>
    <!-- End Info table  -->
    <!-- :is_local="true" -->

    <div class="some_info_about_teacher">
      <span class="d-block">{{ $t("admin_dashboard_type.student_info") }}</span>
      <div class="every_table_action_button text-capitalize">
        <button class="our_btn" @click="showAddStudentDialog = true">
          <el-icon class="el-icon-plus"></el-icon>
          {{ $t("admin_dashboard_type.add_student") }}
        </button>
      </div>
    </div>
    <server-table
      :count="$store.getters['groups/count']"
      :DataItems="studentsData"
      @Refresh="Refresh"
      v-loading="
        $store.getters['user/usersLoad'] ||
        $store.getters['groups/load'] ||
        $store.getters['courses/load']
      "
      :enable_export="false"
      :useFilter="false"
      :action="false"
    >
      <template #columns>
        <el-table-column
          prop="student_name"
          :label="$t('admin_dashboard_type.student_name')"
          min-width="150"
        >
          <template slot-scope="scope">
            <router-link
              class="switch_routing"
              :to="`/groups/group/students/profile/${scope.row.id}`"
            >
              {{ scope.row.student_name }}
            </router-link>
          </template>
        </el-table-column>

        <!-- Start Exam Type  -->
        <el-table-column
          prop="status"
          :label="$t('admin_dashboard_type.torens_exam')"
          min-width="125"
        >
          <template slot-scope="scope">
            <!-- if user not complete 3 exams  -->
            <el-button
              v-if="scope.row.pre_exam == null && scope.row.pre_exam_count != 3"
              size="small"
              type="primary"
              class="el-button btn btn-primary el-button--default ml-1"
            >
              <router-link
                class="switch_routing_to_exam"
                :to="`/exam/1/${scope.row.id}`"
              >
                {{ $t("admin_dashboard_type.pre_exam") }}
              </router-link>
            </el-button>
            <!-- if user complete 3 exams  and redirect to correction exams -->
            <el-button
              v-if="scope.row.pre_exam_count == 3 && scope.row.pre_exam == null"
              size="small"
              type="primary"
              class="el-button btn btn-primary el-button--default ml-1"
            >
              <router-link
                class="switch_routing_to_exam"
                :to="`/exams/1/${scope.row.id}`"
              >
                {{ $t("admin_dashboard_type.pre_exam") }}
              </router-link>
            </el-button>
            <!-- if user complete 3 exams  and redirect exam result -->
            <el-button
              v-if="scope.row.pre_exam != null && scope.row.pre_exam_count == 3"
              size="small"
              type="button"
              class="el-button btn btn-secondary el-button--default"
            >
              <router-link
                class="switch_routing_to_exam"
                :to="`/report/${scope.row.id}/1`"
              >
                {{ $t("admin_dashboard_type.pre_exam_result") }}
              </router-link>
            </el-button>
            <!-- disabled when user hasn't result after exam -->
            <el-button
              size="small"
              type="primary"
              class="el-button btn btn-primary el-button--default ml-1"
              :disabled="scope.row.pre_exam == null"
              v-if="
                scope.row.pre_exam == null && scope.row.last_exam_count == 0
              "
            >
              <span>
                {{ $t("admin_dashboard_type.post_exam") }}
              </span>
            </el-button>
            <el-button
              size="small"
              type="primary"
              class="el-button btn btn-primary el-button--default mr-1"
              v-if="
                scope.row.pre_exam != null && scope.row.last_exam_count != 3
              "
            >
              <router-link
                class="switch_routing_to_exam"
                :to="`/exam/2/${scope.row.id}`"
              >
                {{ $t("admin_dashboard_type.post_exam") }}
              </router-link>
            </el-button>
            <!-- if user complete 3 exams  and redirect to correction exams -->
            <el-button
              v-if="
                scope.row.last_exam_count == 3 && scope.row.after_exam == null
              "
              size="small"
              type="primary"
              class="el-button btn btn-primary el-button--default mr-1"
            >
              <router-link
                class="switch_routing_to_exam"
                :to="`/exams/2/${scope.row.id}`"
              >
                {{ $t("admin_dashboard_type.post_exam") }}
              </router-link>
            </el-button>
            <!-- result for after exam if user doing all 3 exams and pre exam result -->
            <el-button
              v-if="
                scope.row.after_exam != null && scope.row.last_exam_count == 3
              "
              size="small"
              type="button"
              class="el-button btn btn-secondary el-button--default mr-1"
            >
              <router-link
                class="switch_routing_to_exam"
                :to="`/report/${scope.row.id}/2`"
              >
                {{ $t("admin_dashboard_type.post_exam_result") }}
              </router-link>
            </el-button>
          </template>
        </el-table-column>
        <!-- End Exam Type  -->

        <el-table-column label="_" min-width="100">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="danger"
              class="el-button btn btn-danger el-button--default mr-1"
              @click="removeStudentFromGroup(scope.row.id)"
            >
              {{ $t("admin_dashboard_type.delete_from_group") }}
            </el-button>
          </template>
        </el-table-column>
      </template>
    </server-table>

    <!-- Start Add Student  -->
    <el-dialog
      :visible.sync="showAddStudentDialog"
      :title="$t('admin_dashboard_type.add_student')"
      transition="dialog-bottom-transition"
      width="70%"
      top="4vh"
      class="text-right"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <!-- Switch Tabs  -->
      <div class="tabs_heading mb-3">
        <button
          :class="{ is_active: activeTabStudent == 1 }"
          class="our_buttons"
          @click="whichTabIsActive(1)"
        >
          {{ $t("admin_dashboard_type.add_new_student") }}
        </button>
        <button
          :class="{ is_active: activeTabStudent == 2 }"
          class="our_buttons"
          @click="whichTabIsActive(2)"
        >
          {{ $t("admin_dashboard_type.add_more_student") }}
        </button>
        <button
          :class="{ is_active: activeTabStudent == 3 }"
          class="our_buttons"
          @click="whichTabIsActive(3)"
        >
          {{ $t("admin_dashboard_type.add_exist_student") }}
        </button>
      </div>
      <!-- Start Add One Student  -->
      <div v-if="activeTabStudent == 1">
        <el-form
          :model="studentData"
          :rules="addStudentRules"
          ref="addStudentForm"
          class="demo-ruleForm"
          label-position="top"
        >
          <div class="row">
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.student_name')"
                prop="name"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  :label="$t('admin_dashboard_type.student_name')"
                  v-model="studentData.name"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.email')"
                prop="email"
                :rules="[
                  {
                    required: true,
                    type: 'email',
                    message: `${this.$t('correct_email_required')}`,
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-input
                  :label="$t('admin_dashboard_type.email')"
                  v-model="studentData.email"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.password')"
                prop="password"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  :label="$t('admin_dashboard_type.password')"
                  v-model="studentData.password"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.age')"
                prop="age_group"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <multiselect
                  :placeholder="$t('admin_dashboard_type.age')"
                  :searchable="true"
                  :show-labels="true"
                  :close-on-select="true"
                  v-model="studentData.age_group"
                  :multiple="false"
                  :options="age_group"
                  label="title"
                  track-by="id"
                ></multiselect>
              </el-form-item>
            </div>
            <!-- <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.level')"
                prop="grade"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <multiselect
                  :placeholder="$t('admin_dashboard_type.level')"
                  :searchable="true"
                  :show-labels="true"
                  :close-on-select="true"
                  v-model="studentData.grade"
                  :multiple="false"
                  :options="grades"
                  label="title"
                  track-by="id"
                ></multiselect>
              </el-form-item>
            </div> -->
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.gender')"
                prop="gender"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-radio-group v-model="studentData.gender">
                  <el-radio :label="1" @click="studentData.gender = 1">{{
                    $t("admin_dashboard_type.male")
                  }}</el-radio>
                  <el-radio :label="2" @click="studentData.gender = 2">{{
                    $t("admin_dashboard_type.female")
                  }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div class="creative_app_footer_dialog">
          <el-button @click="addStudent" class="when_submit_form save_from">
            {{ $t("admin_dashboard_type.save") }}</el-button
          >
          <el-button
            class="when_submit_form cancel_from"
            @click="showAddStudentDialog = false"
          >
            {{ $t("cancel") }}
          </el-button>
        </div>
      </div>
      <!-- End Add One Student  -->
      <!-- Start Add More Students  -->
      <div v-if="activeTabStudent == 2">
        <div class="upload_excel_wrapper">
          <el-upload
            class="el-upload_btn"
            :show-file-list="false"
            accept=".xlsx"
            :on-success="successImport"
            :action="import_src"
            :on-error="handleErrorUpload"
            :headers="{
              Authorization: 'Bearer ' + getUserToken,
            }"
          >
            <el-icon class="el-icon-upload"></el-icon>
            {{ $t("admin_dashboard_type.add_excel_file") }}
          </el-upload>
        </div>
        <div class="creative_app_footer_dialog">
          <el-button class="when_submit_form save_from">
            {{ $t("admin_dashboard_type.save") }}</el-button
          >
          <el-button
            class="when_submit_form cancel_from"
            @click="showAddStudentDialog = false"
          >
            {{ $t("cancel") }}
          </el-button>
        </div>
      </div>
      <!-- End Add More Students  -->
      <!-- Start Exist Student  -->
      <div v-if="activeTabStudent == 3">
        <el-form class="demo-ruleForm" label-position="top">
          <div class="row">
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.count_of_students')"
                prop="ids"
              >
                <multiselect
                  :placeholder="$t('admin_dashboard_type.choose_students')"
                  :searchable="true"
                  :show-labels="false"
                  :close-on-select="false"
                  v-model="assignStudents.students"
                  :multiple="true"
                  :options="schoolStudentsDropdown"
                  label="name"
                  track-by="id"
                ></multiselect>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div class="creative_app_footer_dialog">
          <el-button
            @click="assignStudentsToGroup"
            :disabled="assignStudents.students.length <= 0"
            class="when_submit_form save_from"
          >
            {{ $t("admin_dashboard_type.save") }}</el-button
          >
          <el-button
            class="when_submit_form cancel_from"
            @click="showAddStudentDialog = false"
          >
            {{ $t("cancel") }}
          </el-button>
        </div>
      </div>
      <!-- Start Exist Student  -->
    </el-dialog>
    <!-- End Add Student -->

    <!-- Start Add Teacher  -->
    <el-dialog
      :visible.sync="showAddTeacherDialog"
      :title="$t('admin_dashboard_type.add_teacher')"
      transition="dialog-bottom-transition"
      width="70%"
      top="4vh"
      class="text-right"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <!-- Switch Tabs  -->
      <div class="tabs_heading mb-3">
        <button
          :class="{ is_active: activeTabTeacher == 1 }"
          class="our_buttons"
          @click="whichTabIsActiveTeacher(1)"
        >
          {{ $t("admin_dashboard_type.add_new_teacher") }}
        </button>
        <button
          :class="{ is_active: activeTabTeacher == 2 }"
          class="our_buttons"
          @click="whichTabIsActiveTeacher(2)"
        >
          {{ $t("admin_dashboard_type.add_exist_teacher") }}
        </button>
      </div>
      <!-- Start Add One Teacher  -->
      <div v-if="activeTabTeacher == 1">
        <el-form
          :model="teacherData"
          :rules="addTeacherRules"
          ref="addTeacherForm"
          class="demo-ruleForm"
          label-position="top"
        >
          <div class="row">
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.teacher_name')"
                prop="name"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  :label="$t('admin_dashboard_type.teacher_name')"
                  v-model="teacherData.name"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.email')"
                prop="email"
                :rules="[
                  {
                    required: true,
                    type: 'email',
                    message: `${this.$t('correct_email_required')}`,
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-input
                  :label="$t('admin_dashboard_type.email')"
                  v-model="teacherData.email"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.password')"
                prop="password"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  :label="$t('admin_dashboard_type.password')"
                  v-model="teacherData.password"
                  class="w-100"
                ></el-input>
              </el-form-item>
            </div>

            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.specialization')"
                prop="specialization"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <multiselect
                  :placeholder="$t('admin_dashboard_type.specialization')"
                  :searchable="true"
                  :show-labels="true"
                  :close-on-select="true"
                  v-model="teacherData.specialization"
                  :multiple="false"
                  :options="specializations"
                  label="label"
                  track-by="val"
                ></multiselect>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.gender')"
                prop="gender"
                :rules="[
                  {
                    required: true,
                    message: `${this.$t('field_required')}`,
                    trigger: 'blur',
                  },
                ]"
              >
                <el-radio-group v-model="teacherData.gender">
                  <el-radio :label="1" @click="teacherData.gender = 1">{{
                    $t("admin_dashboard_type.male")
                  }}</el-radio>
                  <el-radio :label="2" @click="teacherData.gender = 2">{{
                    $t("admin_dashboard_type.female")
                  }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div class="creative_app_footer_dialog">
          <el-button @click="addTeacher" class="when_submit_form save_from">
            {{ $t("admin_dashboard_type.save") }}</el-button
          >
          <el-button
            class="when_submit_form cancel_from"
            @click="showAddTeacherDialog = false"
          >
            {{ $t("cancel") }}
          </el-button>
        </div>
      </div>
      <!-- End Add One Teacher  -->
      <!-- Start Add Exist Teacher -->
      <div v-if="activeTabTeacher == 2">
        <el-form class="demo-ruleForm" label-position="top">
          <div class="row">
            <div class="col-md-6">
              <el-form-item
                :label="$t('admin_dashboard_type.count_of_teachers')"
                prop="teacherIds"
              >
                <multiselect
                  :placeholder="$t('admin_dashboard_type.count_of_teachers')"
                  :searchable="true"
                  :show-labels="false"
                  :close-on-select="false"
                  v-model="assignTeacher.teacher_id"
                  :options="schoolTeachersDropdown"
                  label="name"
                  track-by="id"
                ></multiselect>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div class="creative_app_footer_dialog">
          <el-button
            :disabled="assignTeacher.teacher_id == null"
            @click="assignTeacherToGroup"
            class="when_submit_form save_from"
          >
            {{ $t("admin_dashboard_type.save") }}</el-button
          >
          <el-button
            class="when_submit_form cancel_from"
            @click="showAddTeacherDialog = false"
          >
            {{ $t("cancel") }}
          </el-button>
        </div>
      </div>
      <!-- End Add Exist Teacher -->
    </el-dialog>
    <!-- End Add Teacher -->
  </div>
</template>

<script>
import { getToken } from "../../utils/auth";
import ServerTable from "../../components/DataTablePagination.vue";
import Info from "../../components/Cards/Info.vue";
import Swal from "sweetalert2";
export default {
  name: "groupStudent",
  components: { ServerTable, Info },
  data() {
    return {
      groupInfo: {},
      teacherDetails: {},
      loading: false,
      teacherDataInfo: [
        {
          key: "teacher_name",
          value: "محمد السيد",
        },
        {
          key: "teacher_class",
          value: "مدرس رياضه",
        },
      ],
      // Start Student Details
      showAddStudentDialog: false,
      activeTabStudent: 1,
      studentData: {
        group_id: this.$route.params.id,
        name: null,
        email: null,
        password: null,
        confirm_password: null,
        gender: null,
        grade: null,
        age_group: null,
      },
      addStudentRules: {
        name: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: `${this.$t("correct_email_required")}`,
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        gender: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        grade: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        age_group: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
      },
      studentIds: {
        ids: [],
      },
      students: [
        {
          id: 1,
          name: " المعراج",
        },
        {
          id: 2,
          name: " السيد",
        },
        {
          id: 3,
          name: " سعيد",
        },
        {
          id: 4,
          name: " مصطفى",
        },
        {
          id: 5,
          name: " مازن",
        },
      ],
      // End Student Details
      age_group: [
        { id: 0, title: this.$t("less_than_6") },
        { id: 1, title: 6 },
        { id: 2, title: 7 },
        { id: 3, title: 8 },
        { id: 4, title: 9 },
        { id: 5, title: 10 },
        { id: 6, title: 11 },
        { id: 7, title: 12 },
        { id: 8, title: this.$t("more_than_12") },
      ],
      grades: [
        {
          id: 1,
          title: "Grade 1",
        },
        {
          id: 2,
          title: "Grade 2",
        },
        {
          id: 3,
          title: "Grade 3",
        },
        {
          id: 4,
          title: "Grade 4",
        },
        {
          id: 5,
          title: "Grade 5",
        },
        {
          id: 6,
          title: "Grade 6",
        },
        {
          id: 7,
          title: "Grade 7",
        },
        {
          id: 8,
          title: "Grade 8",
        },
        {
          id: 9,
          title: "Grade 9",
        },
        {
          id: 10,
          title: "Grade 10",
        },
        {
          id: 11,
          title: "Grade 11",
        },
        {
          id: 12,
          title: "Grade 12",
        },
      ],
      // Start Teacher Details
      showAddTeacherDialog: false,
      activeTabTeacher: 1,
      teacherData: {
        group_id: this.$route.params.id,
        name: null,
        email: null,
        password: null,
        gender: null,
        specialization: null,
        confirm_password: null,
      },
      addTeacherRules: {
        name: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: `${this.$t("correct_email_required")}`,
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        gender: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        profession: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
        specialization: [
          {
            required: true,
            message: `${this.$t("field_required")}`,
            trigger: "blur",
          },
        ],
      },
      teacherIds: [],
      specializations: [
        {
          val: "0",
          label: "Art",
        },
        {
          val: "1",
          label: "Computer",
        },
        {
          val: "2",
          label: "Psychology",
        },
        {
          val: "3",
          label: "Skills development",
        },
        {
          val: "4",
          label: "Math",
        },
        {
          val: "5",
          label: "Other",
        },
      ],
      // End Teacher Details
      // {"students" : [1071, 1070 ,1069] , "group_id" : 44}
      // {"teacher_id" : 19  , "group_id" : 44}
      // Assign Teacher And Students To Group
      assignStudents: {
        group_id: this.$route.params.id,
        students: [],
      },
      assignTeacher: {
        group_id: this.$route.params.id,
        teacher_id: null,
      },
    };
  },
  computed: {
    studentsData() {
      return this.$store.getters["groups/groupStudents"];
    },
    count() {
      return this.studentsData.length;
    },
    offset() {
      if (this.$route.query) {
        return (this.$route.query.currentPage - 1) * this.$route.query.perPage;
      } else {
        return 0;
      }
    },
    limit() {
      if (this.$route.query) {
        return this.$route.query.perPage - 1;
      } else {
        return 4;
      }
    },
    schoolStudentsDropdown() {
      return this.$store.getters["schools/schoolStudentsDropdown"];
    },
    schoolTeachersDropdown() {
      return this.$store.getters["schools/schoolTeachersDropdown"];
    },
    getLocale() {
      return this.$i18n.locale;
    },
    import_src() {
      return `${process.env.VUE_APP_BASE_URL}schools/users/import/${this.$route.params.id}`;
    },
    getUserToken() {
      return getToken();
    },
  },
  mounted() {
    // fetch group data from server
    this.loading = true;

    this.Refresh();
    this.handlerGroupInfo();
    this.getStudentsAndTeachersForDropdown();
    this.loading = false;
  },
  methods: {
    Refresh(
      query = { offset: this.offset, limit: this.limit, order_by: "id" }
    ) {
      // pass group id to get students data
      this.$store
        .dispatch("groups/getGroupStudents", {
          id: this.$route.params.id,
          query,
        })
        .then((res) => {
          this.arrs = res;
        });
    },
    handlerGroupInfo() {
      this.$store
        .dispatch("groups/getGroupInfo", {
          id: this.$route.params.id,
        })
        .then((res) => {
          this.groupInfo = Object.entries(
            this.$store.getters["groups/groupInfo"]
          )
            .map(([key, value]) => {
              return { key, value };
            })
            .filter(
              (item) =>
                item.key !== "id" &&
                item.key !== "course_id" &&
                item.key !== "created_at" &&
                item.key !== "updated_at" &&
                item.key !== "search_text" &&
                item.key !== "teacher_id"
            );

          this.teacherDetails = res.teacher_details;
        });
    },
    getStudentsAndTeachersForDropdown() {
      this.$store.dispatch("schools/getListOfSchoolStudentsForDropdown");
      this.$store.dispatch("schools/getListOfSchoolTeachersForDropdown");
    },
    // Student Methods
    whichTabIsActive(num) {
      this.activeTabStudent = num;
    },
    addStudent() {
      this.$refs["addStudentForm"].validate((valid) => {
        if (valid) {
          console.log("datt",this.studentData);

          this.studentData.confirm_password = this.studentData.password;
          if (this.studentData.grade && this.studentData.grade.id) {
        this.studentData.grade = this.studentData.grade.id;
      } else {
        this.studentData.grade = null;
      }

      if (this.studentData.age_group && this.studentData.age_group.id) {
        this.studentData.age_group = this.studentData.age_group.id;
      } else {
        this.studentData.age_group = null;
      }
          this.$store
            .dispatch("schools/handlerAddStudent", this.studentData)
            .then(() => {
              this.showAddStudentDialog = false;
              this.Refresh();
              Swal.fire({
                text: `${this.$t("success_added")}`,
                icon: "success",
                showCancelButton: false,
                confirmButtonClass: "btn btn-success btn-fill",
                confirmButtonText: `${this.$t("confirm")}`,
                buttonsStyling: false,
              });
              this.studentData.name = null;
              this.studentData.email = null;
              this.studentData.password = null;
              this.studentData.confirm_password = null;
              this.studentData.gender = null;
              this.studentData.grade = null;
              this.studentData.age_group = null;
            });
        } else {
          return false;
        }
      });
    },
    assignStudentsToGroup() {
      let studentIds = [];
      this.assignStudents.students.forEach((element) => {
        studentIds.push(element.id);
      });
      this.assignStudents.students = studentIds;
      this.$store
        .dispatch("schools/handlerAssignStudentsToGroup", this.assignStudents)
        .then(() => {
          this.showAddStudentDialog = false;
          this.Refresh();
          Swal.fire({
            text: `${this.$t("success_added")}`,
            icon: "success",
            showCancelButton: false,
            confirmButtonClass: "btn btn-success btn-fill",
            confirmButtonText: `${this.$t("confirm")}`,
            buttonsStyling: false,
          });
          this.assignStudents.students = [];
        });
    },
    // Teacher Methods
    whichTabIsActiveTeacher(num) {
      this.activeTabTeacher = num;
    },
    addTeacher() {
      this.$refs["addTeacherForm"].validate((valid) => {
        if (valid) {
          this.teacherData.confirm_password = this.teacherData.password;
          this.teacherData.specialization = this.teacherData.specialization.val;
          this.$store
            .dispatch("schools/handlerAddTeacher", this.teacherData)
            .then(() => {
              this.showAddTeacherDialog = false;
              this.Refresh();
              this.handlerGroupInfo();
              Swal.fire({
                text: `${this.$t("success_added")}`,
                icon: "success",
                showCancelButton: false,
                confirmButtonClass: "btn btn-success btn-fill",
                confirmButtonText: `${this.$t("confirm")}`,
                buttonsStyling: false,
              });
              this.teacherData.name = null;
              this.teacherData.email = null;
              this.teacherData.password = null;
              this.teacherData.confirm_password = null;
              this.teacherData.gender = null;
              this.teacherData.specialization = null;
            });
        } else {
          return false;
        }
      });
    },
    assignTeacherToGroup() {
      this.assignTeacher.teacher_id = this.assignTeacher.teacher_id.id;
      this.$store
        .dispatch("schools/handlerAssignTeacherToGroup", this.assignTeacher)
        .then(() => {
          this.showAddTeacherDialog = false;
          this.Refresh();
          this.handlerGroupInfo();
          Swal.fire({
            text: `${this.$t("success_added")}`,
            icon: "success",
            showCancelButton: false,
            confirmButtonClass: "btn btn-success btn-fill",
            confirmButtonText: `${this.$t("confirm")}`,
            buttonsStyling: false,
          });
          this.assignTeacher.teacher_id = null;
        });
    },
    whichSpecialization(pcValue) {
      if (pcValue != null) {
        let specialize = this.specializations.filter((item) => {
          if (item.val == pcValue) {
            return item;
          }
        });

        return specialize[0].label;
      } else {
        return "__";
      }
    },
    removeStudentFromGroup(student_id) {
      Swal.fire({
        text: `${this.$t("you_want_delete")}`,
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/blockStudentInGroupStatus", {
              id: this.$route.params.id,
              student_id: student_id,
            })
            .then((_) => {
              this.Refresh();
              Swal.fire({
                text: `${this.$t("success_deleted")}`,
                icon: "success",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleErrorUpload(error, file, fileList) {
      const errorMessage = JSON.parse(error.message)["error"];

      Swal.fire({
        text: `${errorMessage}`,
        type: "error",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false,
        icon: "error",
      });
      this.activeTabStudent = 1;
    },
    successImport() {
      this.showAddStudentDialog = false;
      this.Refresh();
      Swal.fire({
        text: `${this.$t("success_added")}`,
        icon: "success",
        confirmButtonClass: "btn btn-success btn-fill",
        buttonsStyling: false,
      });
      this.activeTabStudent = 1;
    },
  },
};
</script>

<style scoped>
i {
  cursor: pointer;
}

::v-deep .cell {
  text-align: right;
  word-break: break-word;
}

::v-deep sup {
  top: 0;
}

::v-deep sup.el-badge__content {
  height: unset;
  padding: 5px 20px;
}

::v-deep .el-form--label-top .el-form-item__label {
  position: relative;
}

::v-deep .el-form-item__label:before {
  position: absolute;
  left: -12px;
}

::v-deep .el-dialog__headerbtn {
  left: 20px;
  right: unset;
}

::v-deep .el-form-item__error {
  left: unset;
  right: 0;
  font-size: 0.9rem;
}

::v-deep .el-radio {
  margin-left: 30px;
  margin-right: 0;
}

::v-deep .el-radio__input {
  margin-left: 10px;
}

/* form select inputs  */
.el-select-dropdown__item {
  padding: 5px 40px;
  text-align: right;
}

/* rate icons  */
::v-deep .activity-rate .el-icon-star-on:before {
  font-size: 3rem;
}

::v-deep .activity-rate .el-icon-star-off:before {
  font-size: 3rem;
}

::v-deep .el-rate__icon.fa-shield {
  font-size: 3rem;
  margin-right: 1.2rem;
}

::v-deep .el-rate__text {
  margin-right: 1rem;
  font-size: 2rem;
}
</style>

<style lang="scss" scoped>
.wrapper_teacher_info {
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 14px;
  .part_aa {
    text-align: initial;
    background: #f2f2f2;
    display: flex;
    padding: 12px 8px;
    .names {
      min-width: 300px;
    }
    &.part_bb {
      background: #fff;
    }
  }
}

.every_table_action_button {
  padding: 10px 0;
  .our_btn {
    background-color: #409eff;
    color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    transition: 0.5;
    border: 1px solid #409eff;
    &:hover {
      opacity: 0.8;
    }
  }
}
.switch_routing_to_exam {
  // background-color: #409eff;
  // padding: 8px 10px;
  color: #fff;
  text-decoration: none;
}
.some_info_about_teacher {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.tech {
    margin: 10px 0 20px;
  }
}
.school_students_class_page_wrapper {
  .creative_app_footer_dialog {
    display: flex;
    margin-top: 15px;
    justify-content: end;
    padding: 10px;
    gap: 10px;
    .when_submit_form {
      min-width: 150px;
      text-align: center;
      transition: 0.3s;
      &.save_from {
        border: 1px solid #409eff;
        background-color: #409eff;
        color: #fff;
      }
      &.cancel_from {
        border: 1px solid #fb404b;
        background-color: #fb404b;
        color: #fff;
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .tabs_heading {
    display: flex;
    gap: 15px;
    padding: 10px 0;
    border-bottom: 3px solid #e4e4e4;
    .our_buttons {
      border: none;
      background: none;
      color: #777;
      position: relative;
      &::before {
        position: absolute;
        right: 0;
        content: "";
        background: #409eff;
        width: 100%;
        height: 3px;
        bottom: -12px;
        display: none;
      }
      &.is_active {
        &::before {
          display: block;
        }
      }
    }
  }
}
.upload_excel_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 20px;
}
::v-deep .el-upload {
  background-color: #87cb16;
  padding: 20px;
  border-radius: 9px;
  transition: 0.3s;
  font-size: 17px;
  color: #fff;
  &:hover {
    opacity: 0.8;
  }
}
</style>

<style lang="scss">
.school_students_class_page_wrapper {
  .el-dialog__header {
    text-align: initial !important;
    text-transform: capitalize;
  }
  .el-form-item__label {
    text-transform: capitalize;
    width: 100%;
    text-align: initial !important;
  }
  .el-form-item__error {
    width: 100%;
    text-align: initial;
  }
  .el-form-item__content {
    text-align: initial;
    text-transform: capitalize;
  }
}
</style>
